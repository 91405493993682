<template>
  <div class="layout" :style="colorObject">
    <div class="container">
      <Loading
        v-if="isLoading"
        loading-message="載入中..."
        :background-color="'#ffffff'"
      />
      <header class="border-bottom">
        <div class="d-flex justify-content-between align-items-center p-2 px-3">
          <router-link class="logo" :to="{ name: 'LiffPreorderWelcome' }" :style="{
            'text-align': deepGet(preorder, 'config.logo.alignment', 'left'),
          }">
            <img :src="deepGet(preorder, 'config.logo.image') || meta.image" :style="{
              'width': deepGet(preorder, 'config.logo.width') || '100%',
              'margin-top': deepGet(preorder, 'config.logo.top_margin'),
              'margin-bottom': deepGet(preorder, 'config.logo.bottom_margin'),
              'margin-left': deepGet(preorder, 'config.logo.left_margin'),
              'margin-right': deepGet(preorder, 'config.logo.right_margin'),
            }" />
          </router-link>
          <span v-if="isPointMode && shouldDisplayMemberPoints && false === isFetchingMemberPoints">{{ deepGet(preorder, 'config.point_mode.text_before_remaining_point') }}{{ memberPoints }} 點</span>
        </div>
      </header>
      <router-view style="display: flex; flex-direction: column; flex: 1 1 auto;" v-if="preorder"/>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import { themeColorFn } from "@/mixins/liff/themeColor";
import { mapActions, mapGetters, mapState } from "vuex";
import deepGet from "lodash/get";

export default {
  components: {
    Loading,
  },
  mixins: [themeColorFn({ themeConfigPage: "preorder" })],
  data() {
    return {
      isLoading: true,
      isFetchingMemberPoints: true,
    };
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
      getModule: "liffModule/getModule",
    }),
    ...mapGetters('liffPreorder', ['isPointMode', 'shouldDisplayMemberPoints']),
    ...mapState({
      user: (state) => state.liffAuth.user,
    }),
    ...mapState('liffPreorder', { preorder: 'configuration', memberPoints: 'memberPoints' }),
    moduleConfig() {
      return this.getModule("liff_checkin").module_config || {};
    },
    preorderId() {
      return this.$route.params.preorderId
    },
  },
  async mounted() {
    await this.fetchConfiguration(this.preorderId)

    const checkRegisteredPemission = this.deepGet(this.preorder, 'config.permissions', []).includes('registered')

    if (checkRegisteredPemission && !this.user.registered_at) {
      return this.$router.push({ name: "LiffRegisterIndex" })
    }

    if (this.shouldDisplayMemberPoints) {
      await this.fetchMemberPoints()
      this.isFetchingMemberPoints = false
    }

    this.isLoading = false
  },
  methods: {
    deepGet,
    ...mapActions('liffPreorder', ['fetchConfiguration', 'fetchMemberPoints']),
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";
@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);

.layout {
  background-color: var(--liff-layout_bg_color);
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-height: 100vh;
  padding: 0;
  font-family: "Noto Sans TC", "Helvetica";
  background-color: var(--liff-content_bg_color);
}

header {
  position: relative;

  .logo {
    flex: 1 1 auto;
  }
}
</style>
