var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout",style:(_vm.colorObject)},[_c('div',{staticClass:"container"},[(_vm.isLoading)?_c('Loading',{attrs:{"loading-message":"載入中...","background-color":'#ffffff'}}):_vm._e(),_c('header',{staticClass:"border-bottom"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center p-2 px-3"},[_c('router-link',{staticClass:"logo",style:({
          'text-align': _vm.deepGet(_vm.preorder, 'config.logo.alignment', 'left'),
        }),attrs:{"to":{ name: 'LiffPreorderWelcome' }}},[_c('img',{style:({
            'width': _vm.deepGet(_vm.preorder, 'config.logo.width') || '100%',
            'margin-top': _vm.deepGet(_vm.preorder, 'config.logo.top_margin'),
            'margin-bottom': _vm.deepGet(_vm.preorder, 'config.logo.bottom_margin'),
            'margin-left': _vm.deepGet(_vm.preorder, 'config.logo.left_margin'),
            'margin-right': _vm.deepGet(_vm.preorder, 'config.logo.right_margin'),
          }),attrs:{"src":_vm.deepGet(_vm.preorder, 'config.logo.image') || _vm.meta.image}})]),(_vm.isPointMode && _vm.shouldDisplayMemberPoints && false === _vm.isFetchingMemberPoints)?_c('span',[_vm._v(_vm._s(_vm.deepGet(_vm.preorder, 'config.point_mode.text_before_remaining_point'))+_vm._s(_vm.memberPoints)+" 點")]):_vm._e()],1)]),(_vm.preorder)?_c('router-view',{staticStyle:{"display":"flex","flex-direction":"column","flex":"1 1 auto"}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }